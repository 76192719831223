<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Danh sách bình luận
                <small class="text-muted fs-6 fw-bold ms-1 pt-1">Số lượng
                  {{ $numberFormat(items.metaData?.total) }}</small></h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Row-->
            <div class="row g-5 g-lg-10">
              <div class="card card-flush">
                <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                  <!--begin::Card title-->
                  <div class="card-title">
                    <!--begin::Search-->
                    <div class="d-flex align-items-center position-relative my-1">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                      <span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"></path>
												</svg>
											</span>
                      <!--end::Svg Icon-->
                      <input type="text" data-kt-ecommerce-product-filter="search" v-model="filter.walletId"
                             class="form-control form-control-solid w-250px ps-14 me-2" placeholder="Wallet ID">
                      <b-button variant="primary" @click="fetchItems()">Tìm kiếm</b-button>
                    </div>
                    <!--end::Search-->
                  </div>
                  <!--end::Card title-->
                  <!--begin::Card toolbar-->
                  <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                  </div>
                  <!--end::Card toolbar-->
                </div>
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <!--begin::Table-->
                  <div id="kt_ecommerce_category_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                             id="kt_ecommerce_category_table">
                        <!--begin::Table head-->
                        <thead>
                        <!--begin::Table row-->
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                          <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
												">
                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                              <input class="form-check-input" type="checkbox" data-kt-check="true"
                                     data-kt-check-target="#kt_ecommerce_category_table .form-check-input" value="1">
                            </div>
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Tài khoản
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Tác giả
                          </th>
                          <th class="text-start sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Số dư
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Thời gian
                          </th>
                          <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions">
                            Hành động
                          </th>
                        </tr>
                        <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                        <tr class="even" v-for="(item, index) in this.items.data" :key="index">
                          <!--begin::Checkbox-->
                          <td>
                            <div class="form-check  form-check-sm form-check-custom form-check-solid">
                              {{ item.id }}
                            </div>
                          </td>
                          <td>
                            <div class="text-center" v-if="item.user !== null">
                              <a href=""
                                 class="symbol symbol-50px">
                                <img class="symbol-label" :src="$getImage(item.user?.avatar)" alt="" v-if="item.user.avatar !== null">
                                <img class="symbol-label" src="@/assets/images/MotTruyen_LogoApp1.png" alt="" v-else>
                              </a>
                              <div class="ms-3">{{item.user.name}}</div>
                              <div class="ms-3">{{item.user.email}}</div>
                              <div class="ms-3">{{item.user.refCode}}</div>
                            </div>
                          </td>
                          <td >
                            <div class="text-center" v-if="item.author != null">
                              <a href=""
                                 class="symbol symbol-50px">
                                <img class="symbol-label" :src="$getImage(item.author?.image)" alt="">
                              </a>
                              <div class="ms-3">{{item.author?.name}}</div>
                              <div class="ms-3">{{item.author?.email}}</div>
                              <div class="ms-3">{{item.author?.refCode}}</div>
                            </div>
                          </td>
                          <td class="text-center">
                            <div>Xu : {{$numberFormat(item.gold)}}</div>
                            <div>Gỗ : {{$numberFormat(item.point)}}</div>
                          </td>
                          <td class="text-center">
                            {{$formatDateTime(item.updatedAt)}}
                          </td>
                          <td class="text-end">
                            <button class="btn btn-sm btn-primary" @click="walletLog(item.id)">Chi tiết</button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Card body-->
              </div>
            </div>
            <b-modal v-model="modalWalletLog" title="Lịch sử thay đổi" size="xl">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">Số dư</th>
                  <th scope="col">Số tiền</th>
                  <th scope="col">Ghi chú</th>
                  <th scope="col">Ngày</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in itemLogs.data" :key="item.id">
                  <th >
                    <span class="fw-bold">{{ $numberFormat(item.valueTo) }}</span>
                  </th>
                  <td>
                    <span :class="item.value > 0 ? 'text-success' : 'text-danger'" class="fw-bold">{{ $numberFormat(item.value) }}</span>
                  </td>
                  <td>{{ item.message }}</td>
                  <td>{{ $formatDateTime(item.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
              <template #footer>
                <b-button variant="secondary" @click="modalWalletLog = false">Đóng</b-button>
              </template>
            </b-modal>
          </div>
          <!--end::Container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";

export default {
  name: "WalletList",
  computed: {},
  data() {
    return {
      modalWalletLog: false,
      filter: {
        walletId: null,
        sort: 'gold,desc'
      },
      items: {
        data: [],
        metaData: {}
      },
      filterLog: {
        walletId: null,
        sort: 'createdAt,desc',
        walletType: 'GOLD'
      },
      itemLogs: {
        data: [],
        metaData: {}
      },
      modalShow: false,
      dataForm: {},
      loading: {
        page: false,
        user: false,
      },
    };
  },
  mounted() {
    this.fetchItems();
  },
  created() {
  },
  methods: {
    async fetchItems() {
      const response = await requestUtil.get('/admin/wallet', this.filter);
      if (response.status === 200) {
        this.items = response.data
      } else {
        this.$notify({
          title: 'Có lỗi xảy ra',
          type: 'warn',
        });
      }
    },
    async walletLog(id) {
      this.itemLogs.data = [];
      this.filterLog.walletId = id;
      const response = await requestUtil.get('/admin/wallet/log', this.filterLog);
      if (response.status === 200) {
        this.itemLogs = response.data
      } else {
        this.$notify({
          title: 'Có lỗi xảy ra',
          type: 'warn',
        });
      }
      this.modalWalletLog = true;
    },
  },
};

</script>
<style>
</style>