import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/style.bundle.css';
import './assets/plugins/custom/fullcalendar/fullcalendar.bundle.css';
import './assets/plugins/custom/datatables/datatables.bundle.css';
import './assets/plugins/global/plugins.bundle.css';
import store from './stores';
import Notifications from '@kyvg/vue3-notification'
import GlobalPlugin from './plugins/globalPlugin'
import BootstrapVue3 from 'bootstrap-vue-3';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import {setupSocket} from "@/plugins/socket";
const app = createApp(App)
app.use(router)
app.use(store)
app.use(BootstrapVue3)
app.use(Notifications)
app.use(GlobalPlugin)
// Optionally install the BootstrapVue icon components plugin
let script;
script = document.createElement('script');
script.src = '../assets/js/plugins.bundle.js';
script.async = true;
document.head.appendChild(script);
setupSocket(app);
app.mount('#app');
