export default [
    {
        title : 'Dashboard',
        name : 'dashboard',
        icon: '',
    },
    {
        title : 'Nạp rút',
        name : 'wallet_request',
        icon: '',
    },
    {
        title : 'Truyện',
        name : 'story',
        icon: '',
    },
    {
        title : 'Tác giả',
        name : 'author',
        icon: '',
    },
    {
        title : 'Tài khoản',
        name : 'user',
        icon: '',
    },
    {
        title : 'Bình luận',
        name : 'comment',
        icon: '',
    },
    {
        title : 'Thông báo',
        name : 'notification',
        icon: '',
    },
    {
        title : 'Ví',
        name : 'wallet',
        icon: '',
    },
    {
        title : 'Yêu cầu PR',
        name : 'qr_story_request',
        icon: '',
    },
    {
        title : 'Affiliate',
        name : 'affiliate',
        icon: '',
    },
    {
        title : 'Cài đặt chung',
        name : 'setting',
        icon: '',
    }
]