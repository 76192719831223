<template>
  <div class="d-flex flex-column flex-root" @click="checkClickOutside">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Danh sách tác giả
              </h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Navbar-->
            <!--end::Container-->
            <b-tabs content-class="mt-3">
              <b-tab title="Danh sách" active>
                <div class="table-responsive">
                  <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                         id="kt_ecommerce_category_table">
                    <!--begin::Table head-->
                    <thead>
                    <!--begin::Table row-->
                    <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
												">
                        <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <input class="form-check-input" type="checkbox" data-kt-check="true"
                                 data-kt-check-target="#kt_ecommerce_category_table .form-check-input" value="1">
                        </div>
                      </th>
                      <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                          rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                      >Ảnh
                      </th>
                      <th class="text-start sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                          rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending" style="width: 15%;"
                      >Tác giả
                      </th>
                      <th class="sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                          rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending" style="width: 40%;"
                      >Xếp hạng
                      </th>
                      <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                          rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                      >Trạng thái
                      </th>
                      <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                          rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                      >Thời gian
                      </th>
                      <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" aria-label="Actions">
                        Hành động
                      </th>
                    </tr>
                    <!--end::Table row-->
                    </thead>
                    <!--end::Table head-->
                    <!--begin::Table body-->
                    <tbody class="fw-bold text-gray-600">
                    <tr class="even" v-for="(item, index) in this.items.data" :key="index">
                      <!--begin::Checkbox-->
                      <td>
                        <div class="form-check  form-check-sm form-check-custom form-check-solid">
                          {{ item.id }}
                        </div>
                      </td>
                      <!--end::Checkbox-->
                      <!--begin::Category=-->
                      <td>
                        <div class="text-center">
                          <a href=""
                             class="symbol symbol-100px">
                            <img class="symbol-label" :src="$getImage(item.image)" alt="" v-if="item.image != null">
                            <img src="@/assets/images/MotTruyen_LogoApp1.png" alt="" v-else>
                          </a>
                        </div>
                      </td>
                      <td>
                        <span class="fw-bold text-black text-hover-primary">
                          <a :href="`https://mottruyen.vn/tac-gia/${item.name}/${item?.id}`" target="_blank" class="text-black">
                            <AuthorName :showVerify="true" :author="item" />
                          </a>
                        </span>
                        <br>
                        <span class="text-black-50 small">{{$numberFormat(item.countFollower)}} Follow</span> <br>
                        <span class="text-black-50 small" v-if="item.user != null && item?.user.refUserId != null ">Người giới thiệu: {{item.user.refUserId}}</span> <br>
                      </td>
                      <td class="text-start">
                        <div v-html="item.introductionAdmin"></div>
                      </td>
                      <!--begin::Type=-->
                      <td class="text-center">
                        <!--begin::Badges-->
                        <div class="badge badge-light-success me-2" v-if="item.authorStatus === 'ACTIVE'">Hoạt động</div>
                        <div class="badge badge-light-dark me-2" v-if="item.state === 'INACTIVE'">Riêng tư</div>
                        <div class="badge badge-light-danger me-2" v-if="item.state === 'LOCKED'">Tạm khoá</div>
                        <div class="badge badge-light-secondary me-2" v-if="item.state === 'BLOCK'">Block</div>

                        <div class="badge badge-warning" v-if="!item.status">Chờ duyệt</div>
                        <!--end::Badges-->
                      </td>
                      <!--end::Type=-->
                      <td class="text-center">
                        {{ $formatDateTime(item.updatedAt) }}
                      </td>
                      <td class="text-end">
<!--                        <button class="btn btn-sm btn-primary me-1" @click="changeState(item.id, 'APPROVED')">Chi tiết</button>-->
                        <button v-if="!item.status" class="btn btn-sm btn-success me-1" @click="changeState(item.id, 'ACTIVE')">Duyệt</button>
                      </td>
                      <!--end::Action=-->
                    </tr>
                    </tbody>
                    <!--end::Table body-->
                  </table>
                </div>
              </b-tab>
            </b-tabs>
            <!--end::Row-->
          </div>
          <!--end::Container-->
        </div>
      </div>

      <!--end::Wrapper-->
    </div>

  </div>
</template>
<script>

import requestUtil from "@/utils/request-util";
import AuthorName from "@/components/authors/AuthorName.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Author',
  components: {AuthorName},
  data() {
    return {
      isLoading: false,
      items: {
        data: [],
        metaData: {
          page: 0,
          size: 20,
          totalPage: 0,
          total: 0,
          sort: 'createdAt,desc',
        },
      },
    };
  },
  mounted() {
    this.fetchItems();
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  created() {
  },
  methods: {
    async fetchItems() {
      const response = await requestUtil.get('/admin/author', this.items.metaData);
      if (response.status === 200) {
        this.items = response.data
      }
    },
    async changeState(id, status) {
      let data = {
        authorStatus: status,
        id: id
      }
      const response = await requestUtil.post('/admin/author/change_status', data);
      if (response.status === 200) {
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchItems();
      } else {
        this.$notify({
          title: 'Có lỗi xảy ra',
          type: 'warning',
        });
      }
    },
  },
};

</script>
<style>
.menu-sub-dropdown {
  position: absolute;
  z-index: 10000;
}
.banner{
  border-radius: 20px;
  overflow: hidden;
}
.nav-tabs {
  .nav-item {
    color: #a1a5b7;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: color .2s ease, background-color .2s ease;
    padding: .5rem 0;
    margin: 0 1rem;
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 1.15rem !important;
    background: none;

    .nav-link {
      background: none !important;
      color: #5e6278;
      font-weight: 600 !important;
      border: none
    }

    .active {
      border-bottom: 3px solid #009ef7 !important;
      color: #009ef7 !important;

    }
  }
}
</style>