<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <!--begin::Header-->
        <div id="kt_header" class="header">
          <!--begin::Container-->
          <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between"
               id="kt_header_container">
            <!--begin::Page title-->
            <div
                class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0"
                data-kt-swapper="true" data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
              <!--begin::Heading-->
              <h1 class="d-flex flex-column text-dark fw-bolder my-0">Yêu cầu nạp rút
                <small class="text-muted fs-6 fw-bold ms-1 pt-1">Số lượng yêu cầu
                  {{ requestItems.metaData.total }}</small></h1>
              <!--end::Heading-->
            </div>
            <!--end::Page title=-->
            <!--begin::Wrapper-->
            <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
              <!--begin::Aside mobile toggle-->
              <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"/>
											<path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"/>
										</svg>
									</span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Aside mobile toggle-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Container-->
          <div class="container-fluid" id="kt_content_container">
            <!--begin::Row-->
            <div class="row g-5 g-lg-10">
              <div class="card card-flush">
                <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                  <!--begin::Card title-->
                  <div class="card-title">
                    <!--begin::Search-->
                    <div class="d-flex align-items-center position-relative my-1">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                      <span class="svg-icon svg-icon-1 position-absolute ms-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
													<path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"></path>
												</svg>
											</span>
                      <!--end::Svg Icon-->
                      <input type="text" data-kt-ecommerce-product-filter="search"
                             class="form-control form-control-solid w-250px ps-14" placeholder="Search Product">
                    </div>
                    <!--end::Search-->
                  </div>
                  <!--end::Card title-->
                  <!--begin::Card toolbar-->
                  <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div class="w-100 mw-150px">
                      <!--begin::Select2-->
                      <select name="" v-model="filter.state" id="" class="form-control" @change="fetchRequest()">
                        <option value="PENDING">Chờ duyệt</option>
                        <option value="APPROVED">Đã duyệt</option>
                        <option value="REJECT">Từ chối</option>
                      </select>

                      <!--end::Select2-->
                    </div>
                    <div class="w-100 mw-150px">
                      <!--begin::Select2-->
                      <select name="" id="" v-model="filter.type" class="form-control" @change="fetchRequest()">
                        <option value="WITHDRAW">Rút</option>
                        <option value="RECHARGE">Nạp</option>
                      </select>
                      <!--end::Select2-->
                    </div>
                    <!--begin::Add product-->
                    <!--                  <a href="javascript:void(0)" class="btn btn-primary">Thêm mới</a>-->
                    <b-button variant="primary" v-b-modal.modal-create-request>Thêm mới</b-button>
                    <b-modal id="modal-create-request" ref="createRequestModal" hide-footer title="Thêm mới yêu cầu">
                      <div class="row">
                        <div class="col-6">
                          <div class="fv-row fv-plugins-icon-container">
                            <label class="required form-label">Wallet ID</label>
                            <input type="number" v-model="dataForm.walletId" class="form-control mb-2"
                                   @blur="loadUser()"
                                   placeholder="Nhập wallet id"
                                   value="">
                            <span class="text-danger"
                                  v-if="dataFormErrorMessages.walletId">{{ dataFormErrorMessages.walletId }}</span>
                          </div>
                        </div>
                        <div class="col-6 bg-secondary-subtle border-left-1">
                          <div v-if="walletUser.user != null">
                            <div class="mb-2">{{ walletUser.user?.name }}</div>
                            <div class="mb-2">{{ walletUser.user?.email }}</div>
                            <div class="">Số dư: <span
                                class="text-danger fw-bold">{{ numberFormat(walletUser.wallet?.gold) }}</span></div>
                          </div>
                          <div class="d-flex justify-content-center align-items-center pt-4">
                            <b-spinner variant="primary" label="Spinning" v-if="loading.user"></b-spinner>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Nạp/Rút</label>
                          <div class="row">
                            <div class="col-6">
                              <b-form-radio v-model="dataForm.type" name="some-radios" value="RECHARGE">Nạp
                              </b-form-radio>
                            </div>
                            <div class="col-6">
                              <b-form-radio v-model="dataForm.type" name="some-radios" value="WITHDRAW">Rút
                              </b-form-radio>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Số tiền</label>
                          <input type="number" v-model="dataForm.amount" class="form-control mb-2"
                                 placeholder="Số tiền"
                                 value="">
                          <span class="text-danger"
                                v-if="dataFormErrorMessages.amount">{{ dataFormErrorMessages.amount }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <label class="required form-label">Ghi chú</label>
                          <input type="text" v-model="dataForm.note" class="form-control mb-2"
                                 placeholder="Ghi chú" value="">
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="mt-5 fv-row fv-plugins-icon-container">
                          <div class="row">
                            <div class="col-6">
                              <b-button variant="secondary" class="me-3" data-bs-dismiss="modal">Đóng</b-button>
                            </div>
                            <div class="col-6 text-end">
                              <b-button variant="primary" @click="doSubmit()">Hoàn thành</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-modal>
                    <!--end::Add product-->
                  </div>
                  <!--end::Card toolbar-->
                </div>
                <!--begin::Card body-->
                <div class="card-body pt-0">
                  <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                    <!--begin::Nav item-->
                    <li class="nav-item mt-2">
                      <a class="nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer"
                         :class="tabActive === 'WITHDRAW' ? 'active' : ''" @click="activeTab('WITHDRAW')">Rút</a>
                    </li>
                    <!--end::Nav item-->
                    <!--begin::Nav item-->
                    <li class="nav-item mt-2">
                      <a class="nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer"
                         :class="tabActive === 'RECHARGE' ? 'active' : ''" @click="activeTab('RECHARGE')">Nạp</a>
                    </li>
                    <!--end::Nav item-->
                  </ul>
                  <!--begin::Table-->
                  <div id="kt_ecommerce_category_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                             id="kt_ecommerce_category_table">
                        <!--begin::Table head-->
                        <thead>
                        <!--begin::Table row-->
                        <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                          <th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" aria-label="
												">
                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                              <input class="form-check-input" type="checkbox" data-kt-check="true"
                                     data-kt-check-target="#kt_ecommerce_category_table .form-check-input" value="1">
                            </div>
                          </th>
                          <th class="sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Người yêu cầu
                          </th>
                          <th class="sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category: activate to sort column ascending"
                          >Hình thức
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Số tiền
                          </th>
                          <th class="sorting text-center" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Trạng thái
                          </th>
                          <th class="text-center sorting" tabindex="0" aria-controls="kt_ecommerce_category_table"
                              rowspan="1" colspan="1" aria-label="Category Type: activate to sort column ascending"
                          >Thời gian
                          </th>
                          <th class="text-end min-w-200px sorting_disabled" rowspan="1" colspan="1"
                              aria-label="Actions">
                            Hành động
                          </th>
                        </tr>
                        <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="fw-bold text-gray-600">
                        <!--begin::Table row-->

                        <!--end::Table row-->


                        <tr class="even" v-for="(item, index) in this.requestItems.data" :key="index">
                          <!--begin::Checkbox-->
                          <td>
                            {{ item.id }}
                          </td>
                          <!--end::Checkbox-->
                          <!--begin::Category=-->
                          <td>
                            <div class="text-start">
                              <div>Tài khoản: {{ item.user?.name }} ({{ item.walletId }})</div>
                              <div v-if="item.author != null">Tác giả : <a
                                  :href="`https://mottruyen.vn/tac-gia/${item.author?.name}/${item.author?.id}`"
                                  target="_blank"
                              >
                                {{ item.author?.name }} ({{ item.author?.id }})
                              </a></div>
                            </div>
                          </td>
                          <td>

                            <div class="text-start">
                              <div class="">

                                <a href="#" class="btn btn-sm fw-bolder ms-2 fs-8 py-1 px-3"
                                   :class="item?.paymentMethod?.type === 'BANK' ? 'btn-light-primary' : 'btn-light-danger'">{{ item?.paymentMethod?.type }}</a>
                              </div>
                              <ul v-if="item?.transactionAcbLog != null">
                                <li>{{ item?.transactionAcbLog.transactionID }}</li>
                                <li>{{ item?.transactionAcbLog.amount }}</li>
                                <li>{{ item?.transactionAcbLog.description }}</li>
                                <li>{{ item?.transactionAcbLog.transactionDate }}</li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div class="fw-bold text-danger text-center" v-if="item.type === 'RECHARGE'">
                              - {{ numberFormat(item.amount) }}
                            </div>
                            <div class="fw-bold text-success text-center" v-else>
                              + {{ numberFormat(item.amount) }}
                            </div>
                          </td>
                          <!--begin::Type=-->
                          <td class="text-center">
                            <!--begin::Badges-->
                            <div class="badge badge-light-success " v-if="item.state === 'APPROVED'">Hoàn thành</div>
                            <div class="badge badge-light-secondary" v-if="item.state === 'FAIL'">Không thành công</div>
                            <div class="badge badge-light-danger" v-if="item.state === 'REJECT'">Từ chối</div>
                            <div class="badge badge-light-warning" v-if="item.state === 'PENDING'">Chờ duyệt</div>
                            <!--end::Badges-->
                          </td>
                          <!--end::Type=-->

                          <td class="text-center">
                            {{ $formatDateTime(item.createdAt) }} <br>
                            {{ item.note }}
                          </td>
                          <!--begin::Action=-->
                          <td class="text-end">
                            <button class="btn btn-sm btn-primary me-2" v-if="item.type !== 'RECHARGE'" @click="showModalQr(item)">
                              <i class="bi bi-qr-code"></i>
                            </button>
                            <div v-if="item.state === 'PENDING'">
                              <button class="btn btn-sm btn-success me-2" @click="changeState(item.id, 'APPROVED')">
                                <i class="bi bi-check-circle-fill"></i>
                              </button>
                              <button class="btn btn-sm btn-warning me-2" @click="changeState(item.id, 'REJECT')">
                                <i class="bi bi-x-circle-fill"></i>
                              </button>
                              <button v-if="item.type === 'RECHARGE'" class="btn btn-sm btn-danger"
                                      @click="deleteRequest(item.id)"><i class="bi bi-trash-fill"></i>
                              </button>
                            </div>
                          </td>
                          <!--end::Action=-->
                        </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                    </div>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Card body-->
              </div>
            </div>

          </div>
          <!--end::Container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <b-modal id="modal-qr" v-model="modalQr" title="Mã QR Code" hide-footer>
      <div v-if="itemSelectQr != null" class="text-center">
        <img
            :src="`https://img.vietqr.io/image/${itemSelectQr.paymentMethod?.bank?.code.toLowerCase()}-${itemSelectQr.paymentMethod?.accountNumber}-print.png?accountName=${itemSelectQr.paymentMethod?.accountName}&addInfo=mottruyen%20rut%20xu&amount=${itemSelectQr.amount}`"
            alt=""
            v-if="itemSelectQr.paymentMethod?.type==='BANK'"
        />
        <div v-else>
          <div v-if="itemSelectQr.type === 'WITHDRAW'">
            <div v-if="itemSelectQr.byAdmin" class="fw-bold">
              Bởi admin
            </div>
            <div v-else>
              <img
                  :src="`https://vietqr.co/api/generate/momo/${itemSelectQr.paymentMethod?.accountNumber}/${itemSelectQr.paymentMethod?.accountName}/${itemSelectQr.amount}/mottruyen%20rut%20xu?isMask=0&logo=1&style=2&bg=61`"
                  alt="">
              <div class="px-4">MOMO</div>
              <div>{{ itemSelectQr.paymentMethod?.accountNumber }}</div>
              <div>{{ itemSelectQr.paymentMethod?.accountName }}</div>
            </div>
          </div>
          <div v-else class="text-start">
            <ul v-if="itemSelectQr.transactionAcbLog != null">
              <li>{{ itemSelectQr.transactionAcbLog.transactionID }}</li>
              <li>{{ itemSelectQr.transactionAcbLog.amount }}</li>
              <li>{{ itemSelectQr.transactionAcbLog.description }}</li>
              <li>{{ itemSelectQr.transactionAcbLog.transactionDate }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="float-end" v-if="itemSelectQr != null">
        <b-button size="sm" variant="secondary" class="me-2">
          Đóng
        </b-button>

        <b-button size="sm" variant="danger" @click="changeState(itemSelectQr.id, 'REJECT')" class="me-2">
          Từ chối
        </b-button>
        <b-button size="sm" variant="success" @click="changeState(itemSelectQr.id, 'APPROVED')">
          Duyệt
        </b-button>

      </div>
    </b-modal>
    <!--end::Page-->
  </div>
</template>
<script>
import requestUtil from "@/utils/request-util";

export default {
  name: 'WalletRequest',
  data() {
    return {
      requestItems: {
        data: [],
        metaData: {}
      },
      modalShow: false,
      modalQr: false,
      tabActive: 'WITHDRAW',
      dataForm: {
        walletId: null,
        amount: null,
        type: 'RECHARGE',
        note: null,
      },
      filter: {
        type: 'WITHDRAW',
        state: null,
        role: 'admin',
        size: 20,
        page: 0,
      },
      walletUser: {},
      loading: {
        page: false,
        user: false,
      },
      itemSelectQr: null,
      dataFormErrorMessages: {
        walletId: null,
        amount: null,
        type: null,
        note: null,
        valid: true,
      }
    };
  },
  mounted() {
    this.fetchRequest();
  },
  created() {
  },
  methods: {
    showModalQr(item) {
      console.log(item);
      this.itemSelectQr = item;
      this.modalQr = true;
    },
    activeTab(value) {
      this.tabActive = value;
      this.filter.type = value;
      this.filter.page = 0;
      this.fetchRequest();
    },
    async doSubmit() {
      //valid
      // this.formValid();
      if (this.dataFormErrorMessages.valid === false) {
        return
      }
      try {
        const response = await requestUtil.post('/admin/wallet/request', this.dataForm);
        if (response.status === 200) {
          await this.fetchRequest();
        } else {
          this.$notify({
            title: 'Lỗi xảy ra',
            type: 'warning',
          });
        }
      } catch (e) {
        this.$notify({
          title: 'Không tìm thấy người dùng',
          type: 'warning',
        });
      }
    },
    async loadUser() {
      this.loading.user = true;
      try {
        const response = await requestUtil.get('/admin/wallet/' + this.dataForm.walletId);
        if (response.status === 200) {
          this.walletUser = response.data
        } else {
          this.$notify({
            title: 'Không tìm thấy người dùng',
            type: 'warning',
          });
        }
      } catch (e) {
        this.$notify({
          title: 'Không tìm thấy người dùng',
          type: 'warning',
        });
      }
      this.loading.user = false;

    },
    async fetchRequest() {
      const response = await requestUtil.get('/wallet/request', this.filter);
      if (response.status === 200) {
        this.requestItems = response.data
      }
    },
    async changeState(id, state) {
      let dataForm = {
        requestId: id,
        state: state,
        note: ""
      }
      try {
        await requestUtil.post('/wallet/request/change_state', dataForm);
        this.$notify({
          title: 'Thành công',
          type: 'success',
        });
        await this.fetchRequest();
      } catch (e){
        this.$notify({
          title: 'Thất bại',
          type: 'error',
          text: e.data.message,
        });
      }
      // window.location.reload
    },
    async deleteRequest(id) {
      if (window.confirm("Bạn có chắc chắn xóa?")) {
        const response = await requestUtil.get('/admin/wallet/request/' + id + '/delete');
        if (response.status === 200) {
          this.$notify({
            title: 'Thành công',
            type: 'success',
          });
          await this.fetchRequest();
        } else {
          this.$notify({
            title: 'Thành công',
            type: 'success',
            text: response.data.message
          });
        }
        window.location.reload
      } else {
        // Người dùng bấm Cancel
      }

    },
    numberFormat(value) {
      return (value ?? 0).toLocaleString('en-US');
    },
  },
};

</script>
<style>
</style>